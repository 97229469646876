html {
    scroll-behavior: smooth;
}

.home {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 21.875%;
    @media (max-width: 1360px) {
        padding: 0 8%;
    }
    @media (max-width: 900px) {
        padding: 0 4%;
    }
    row-gap: 8em;

    overflow-x: hidden;
    
    .landing {
        text-align: center;
        height: 100vh;

        @media (max-width: 900px) {
            height: 70vh;
        }

        .header {
            margin-top: 21vh;

            @media (max-width: 900px) {
                // margin-top: 15vh;
            }

            .header_text_container {
                display: flex;
                align-items: center;
                justify-content: center;

                .header_logo {
                    width: 48vw;
                    object-fit: cover;

                    @media (max-width: 900px) {
                        width: 20em;
                    }
                }
            }

            h1 {
                font-family: "PP Editorial New";
                font-weight: 400;
                font-size: 7.6em;
                font-size: 6.5vw;
                
                @media (max-width: 1360px) {
                    font-size: 11vw;

                    @media (min-resolution: 1.25dppx) {
                        font-size: 8vw;
                    }
                }
                @media (max-width: 900px) {
                    font-size: 12vw;
                    word-wrap: break-word;
                    white-space: normal;
                    line-height: 1.2;
                }
            }
        
            .copy {
                color: #3a475a;
                // margin-top: -1.6em; // for text header
                margin-top: 0.4em; // for image header
                display: flex;
                flex-direction: column;

                @media (max-width: 900px) {
                    margin-top: 1em;
                    font-size: 0.8em;
                }
        
                p {
                    font-family: "Neue Haas Grotesk Text Pro";
                    font-weight: 400;
                    font-size: 1em;
                    line-height: 1.4em;

                    @media (max-width: 900px) {
                        font-size: 1em;
                    }

                    // overflow: hidden;
                    // white-space: nowrap;
                    // margin: 0 auto;
                    // border-right: 0.15em solid #3A475A;
                    // animation: typing 4s steps(30, end), blink_caret 0.75s step-end infinite;
                    
                    // @keyframes typing {
                    //     from { width: 0; }
                    //     to { width: 100%; }
                    // }
                    
                    // @keyframes blink_caret {
                    //     from, to { border-color: transparent; }
                    //     50% { border-color: #3A475A; }
                    // }
                }
                .location {
                    // animation-delay: 50ms;
                    font-size: 1.2em;
                    font-weight: 600;
                    
                    @media (max-width: 1360px) {
                        font-size: 1.2em;
                    }
                    
                    @media (max-width: 900px) {
                        font-size: 1em;
                    }

                    a {
                        color: #3a475a;
                        text-decoration: underline;
                        transition: color 0.1s ease-out;

                        &:hover {
                            color: #3a475aD0;
                        }
                    }
                }
                
                .tagline {
                    // animation-delay: 0s;
                    margin-top: 0.2em;
                }
            }

            .countdown {
                display: flex;
                justify-content: center;
                align-items: center;
                
                a {
                    color: #3a475a;
                    font-weight: 600;
                    font-size: 5em;
                    transition: color 0.1s ease-out;

                    @media (max-width: 900px) {
                        // margin-top: 2em;
                        font-size: 3em;
                    }

                    &::after {
                        content: "until hacking ends.";
                        display: block;
                        font-size: 0.2em;
                        margin-top: -1.4em;
                        margin-left: 10.4em;
                        width: 10em;
                    }
                    
                    &:hover {
                        color: #3a475aD0;
                    }
                }
            }
        
            .buttons {
                margin-top: 2em;
                display: flex;
                justify-content: center;
                align-items: center;
                column-gap: 1em;
                
                @media (max-width: 900px) {
                    flex-wrap: wrap;
                    margin-top: 1.2em;   
                    column-gap: 0.8em;
                    row-gap: 1.5em
                }
            }
        
            .socials {
                margin-top: 0.6em;

                @media (max-width: 900px) {
                    margin-top: 0.8em;
                }
            }
        }
    }

    .clouds {
        width: 1000em;
        height: 40em;
        object-fit: cover;
        justify-self: center;
        position: absolute;  
        left: 0;
        right: 0;
        // top: 38em;
        top: 52vh;
        pointer-events: none;
        
        @media (min-resolution: 1.25dppx) and (max-width: 1360px) {
            width: 100vw;
            height: 50vh;
            // bottom: -10vh;
        }
        @media (max-width: 900px) {
            width: 500em;
            height: 15em;
            // bottom: 3em;
        }
    }
    
    .rocket_container {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        top: 52vh;
        pointer-events: none;
        touch-action: none;

        @media (min-resolution: 1.25dppx) and (max-width: 1360px){
            top: 60vh;
        }
        @media (max-width: 900px) {
            top: 50vh;
        }

        .rocket {
            max-width: 32em;
            object-fit: cover;
            pointer-events: none;
            
            @media (min-resolution: 1.25dppx) and (max-width: 1360px) {
                max-width: 70vw;
                max-height: 70vh;
            }
            @media (max-width: 900px) {
                max-width: 15em;
            }
        }
    }

    .about {
        color: #3A475A;
        padding: 0 8em;

        @media (max-width: 900px) {
            padding: 0 1em;
            margin-top: 4em;
        }

        .copy {
            margin-top: 6em;
            font-family: "PP Editorial New";
            font-size: 2em;
            font-weight: 400;
            line-height: 1.5em;

            p {
                margin-bottom: 1.2em;

                @media (max-width: 900px) {
                    margin-bottom: 0.8em;
                }
            }

            .hv {
                color: #7441ff;
                font-style: italic;
                font-size: 2em;

                @media (max-width: 900px) {
                    font-size: 1.8em;
                }
            }

            @media (max-width: 900px) {
                font-size: 1.2em;
            }
        }
    }

    .island_container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin-bottom: 4em;

        .island {
            max-width: 32em;
            object-fit: cover;
            pointer-events: none;
            
            @media (min-resolution: 1.25dppx) and (max-width: 1360px) {
                max-width: 70vw;
                max-height: 70vh;
            }
            @media (max-width: 900px) {
                max-width: 15em;
            }
        }
    }

    .gallery {
        width: 100%;

        @media (max-width: 900px) {
            width: 100vw;            
        }
    }

    .blob {
        position: absolute;
        overflow: visible;
        object-fit: none;
        width: 100vw;
        height: auto;
        // margin-top: 10em;
        margin-top: -20em;
        z-index: -1000;
        left: 0;
        filter: saturate(1.1) brightness(1.1);

        @media (max-width: 900px) {
            margin-bottom: -6em;
        }

        @media (max-width: 762px) {
            margin-bottom: 45em;
        }
    }

    .faq {
        scroll-margin-top: 10em;
        .header {
            text-align: center;
            font-family: "PP Editorial New";
            font-size: 4em;
            font-weight: 400;
            font-style: italic;
            color: #ffffff;

            @media (max-width: 900px) {
                font-size: 2.5em;
            }
        }

        // &::before {
        //     content: "";
        //     display: block;
        //     position: absolute;
        //     left: 0;
        //     width: 100%;
        //     height: 20em;
        //     background-image: url(".././asset/image/blob.png");
        // }
    }

    .schedule {
        scroll-margin-top: 10em;
        .header {
            text-align: center;
            font-family: "PP Editorial New";
            font-size: 4em;
            font-weight: 400;
            font-style: italic;
            color: #3A475A;

            @media (max-width: 900px) {
                font-size: 3em;
            }
        }
    }

    .prizes {
        scroll-margin-top: 10em;
        width: 100%;
        .header {
            text-align: center;
            font-family: "PP Editorial New";
            font-size: 4em;
            font-weight: 400;
            font-style: italic;
            color: #3A475A;

            @media (max-width: 900px) {
                font-size: 2.5em;
            }
        }
    }

    .sponsors {
        scroll-margin-top: 10em;
        .header {
            text-align: center;
            font-family: "PP Editorial New";
            font-size: 4em;
            font-weight: 400;
            font-style: italic;
            color: #3A475A;
            margin-bottom: 0.5em;

            @media (max-width: 900px) {
                font-size: 3em;
            }
        }
    }

    .board {
        width: 100%;

        .header {
            text-align: center;
            font-family: "PP Editorial New";
            font-size: 4em;
            font-weight: 400;
            font-style: italic;
            color: #3A475A;
            margin-bottom: 0.5em;
        }
    }

    .footer {
        margin-top: 15em;
        width: 100%;
    }

    .footer_blob {
        position: absolute;
        overflow: visible;
        object-fit: none;
        height: 50em;
        width: 1000em;
        bottom: 0;
        left: 0;
        z-index: -1000;
        filter: saturate(1.1) brightness(1.1);
    }
}
