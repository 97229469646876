.button {
    font-family: "PP Supply Sans";
    font-weight: 400;
    font-size: 1em;
    text-decoration: none;

    padding: 0.6em 1.5em;
    padding-top: 0.8em;
    border: 1px solid #3a475a;
    border-radius: 100em;
    color: white;
    background-color: #3a475a;

    transition: all 0.1s ease-out;

    &:hover {
        background-color: #3a475aD0;
    }
    
    @media (max-width: 900px) {
        padding-top: 0.8em;
        font-size: 0.8em;
    }
    
    cursor: pointer;
    
    &__clear {
        color: #3a475a;
        background-color: transparent;
        
        &:hover {
            color: #3a475aD0;
            background-color: transparent;
        }
    }
}
