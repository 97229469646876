html {
    scroll-behavior: smooth;
}

.resources {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 21.875%;
    @media (max-width: 1360px) {
        padding: 0 8%;
    }
    @media (max-width: 900px) {
        padding: 0 4%;
        row-gap: 2em;
    }
    row-gap: 8em;

    overflow-x: hidden;
    
    .landing {
        text-align: center;
        height: 100vh;

        @media (max-width: 900px) {
            height: 70vh;
        }

        .header {
            margin-top: 36vh;

            @media (max-width: 900px) {
                margin-top: 20vh;
            }

            h1 {
                font-family: "PP Editorial New";
                font-weight: 400;
                font-size: 7.6em;
                font-size: 6.5vw;
                
                @media (max-width: 1360px) {
                    font-size: 11vw;

                    @media (min-resolution: 1.25dppx) {
                        font-size: 8vw;
                    }
                }
                @media (max-width: 900px) {
                    font-size: 15vw;
                    word-wrap: break-word;
                    white-space: normal;
                    line-height: 1.2;
                }
            }
        
            .copy {
                margin-top: -1.6em;
                display: flex;
                flex-direction: column;

                @media (max-width: 900px) {
                    margin-top: 1em;
                    font-size: 0.8em;
                }
        
                p {
                    font-family: "Space Grotesk";
                    font-weight: 400;
                    font-size: 1.2em;
                    line-height: 1.4em;

                    @media (max-width: 900px) {
                        font-size: 1em;
                    }
                }

                .p1 {
                    animation-delay: 0s;
                }
                .p2 {
                    animation-delay: 50ms;
                }
            }
        
            .buttons {
                margin-top: 1.8em;
                display: flex;
                justify-content: center;
                align-items: center;
                column-gap: 1em;
                
                @media (max-width: 900px) {
                    flex-wrap: wrap;
                    margin-top: 1.2em;   
                    column-gap: 0.8em;
                    row-gap: 1.5em
                }
            }
        
            .socials {
                margin-top: 1em;

                @media (max-width: 900px) {
                    margin-top: 0.8em;
                }
            }
        }
    }

    .clouds {
        width: 1000em;
        height: 35em;
        object-fit: cover;
        justify-self: center;
        position: absolute;  
        left: 0;
        right: 0;
        // top: 38em;
        top: 60vh;
        pointer-events: none;
        
        @media (min-resolution: 1.25dppx) and (max-width: 1360px) {
            width: 100vw;
            height: 50vh;
            // bottom: -10vh;
        }
        @media (max-width: 900px) {
            width: 500em;
            height: 10em;
            // bottom: 3em;
        }
    }
    
    .rocket_container {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        top: 50vh;

        @media (min-resolution: 1.25dppx) and (max-width: 1360px){
            top: 60vh;
        }
        @media (max-width: 900px) {
            top: 50vh;
        }

        .rocket {
            max-width: 32em;
            object-fit: cover;
            pointer-events: none;
            
            @media (min-resolution: 1.25dppx) and (max-width: 1360px) {
                max-width: 70vw;
                max-height: 70vh;
            }
            @media (max-width: 900px) {
                max-width: 15em;
            }
        }

    }

    .text {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0 4em;
        @media (max-width: 900px) {
            padding: 0 1em;
        }
    
        .copy {
            margin-bottom: 1em;
            font-family: "Neue Haas Grotesk Text Pro";
            font-size: 1.4em;
            font-weight: 400;
            line-height: 1.4em;
            width: 100vw;
            max-width: 45vw;
        
            p {
                margin: 1.2em 1.2em;
            }

            ul {
                margin-top: 1.2em;
            }
            li {
                margin-bottom: 0.8em;
            }

            .indented {
                margin-left: 20px;
            }
            
            @media (max-width: 1360px){
                width: 100vw;
                max-width: 65vw;
    
                @media (min-resolution: 1.25dppx) {
                    width: 100vw;
                    max-width: 65vw;
                }
            }
            @media (max-width: 900px) {
                font-size: 1em;
                width: 100vw;
                max-width: 80vw;
            }
        }
    }

    .section {
        scroll-margin-top: 10em;

        .header {
            text-align: center;
            font-family: "PP Editorial New";
            font-size: 4em;
            font-weight: 400;
            font-style: italic;
            color: black;

            @media (max-width: 900px) {
                font-size: 2.5em;
            }
        }

        .text {
            font-size: 0.9em;
        }
    }

    .submission {
        scroll-margin-top: 10em;
        .submission_header {
            text-align: center;
            font-family: "PP Editorial New";
            font-size: 4em;
            font-weight: 400;
            font-style: italic;
            color: black;

            @media (max-width: 900px) {
                font-size: 2.5em;
            }
        }
    }

    .starter {
        scroll-margin-top: 10em;
        .starter_header {
            text-align: center;
            font-family: "PP Editorial New";
            font-size: 4em;
            font-weight: 400;
            font-style: italic;
            color: black;

            @media (max-width: 900px) {
                font-size: 2.5em;
            }
        }
    }

    .footer {
        margin-top: 15em;
        width: 100%;
    }

    .footer_blob {
        position: absolute;
        overflow: visible;
        object-fit: none;
        height: 50em;
        width: 1000em;
        bottom: 0;
        left: 0;
        z-index: -1000;
        filter: saturate(1.1) brightness(1.1);
    }
}
