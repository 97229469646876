.navigation {
    padding: 0 21.875%;
    top: 1em;
    position: fixed;
    width: 100%;
    font-family: "Neue Haas Grotesk Text Pro";
    z-index: 1000;

    @media (max-width: 1360px) {
        padding: 0 11.875%;
        @media (min-resolution: 1.25dppx) {
            padding: 0 21.875%;
        }
    }
    @media (max-width: 900px) {
        padding: 0 15.875%;
        top: 1em;
    }

    
    .bar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        
        height: 3.8em;
        width: 100%;
        padding: 0 3em;
        border-radius: 1em;
        border: 1px solid #0000001A;
        backdrop-filter: blur(16px) saturate(120%);
        background-color: #7441FF0D; 

        @media (max-width: 900px) {
            padding: 0 1em;
        }

        .logo {
            font-weight: 800;

            a {
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 0.6em 0;

                .icon {
                    width: 2em;
                }

                .text {
                    width: 8em;
                    padding-left: 0.5em;

                    @media (max-width: 900px) {
                        width: 5em;
                        padding-left: 0.2em;
                    }
                }

                p {
                    margin-top: 0.2em;
                    margin-left: 0.4em;
                    font-size: 1.2em;
                }
            }
        }

        .links {
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 0.2em;
            margin-left: 0.4em;
            font-size: 1.05em;

            a {
                padding: 0.6em 1em;

                &:after {
                    content: "";
                    display: block;
                    height: 1px;
                    background: #3a475a;

                    transition: all 0.1s ease-out;
                    opacity: 0;
                    transform: translate(0, 0.2em) scaleX(0.98);
                }
                
                &:hover:after {
                    transition: all 0.1s ease-out;
                    opacity: 1;
                    transform: translate(0, -0.05em) scaleX(0.98);
                }
            }

            @media (max-width: 900px) {
                display: none;
            }
        }

        .resources {
            padding: 0;
            display: flex;
            justify-content: center;
            align-items: center;

            .resources_button {
                cursor: pointer;
                padding: 0.5em 2.3em;
                padding-top: 0.6em;
                background-color: #6c42e0;
                border-radius: 100em;
                border-color: transparent;
                
                display: flex;
                justify-content: center;
                align-items: center;
                
                font-size: 0.9em;
                color: #ffffff;

                transition: background-color 0.1s ease-out;

                &:hover {
                    background-color: #6c42e0DD;
                }

                @media (max-width: 900px) {
                    font-family: "PP Supply Sans";
                    font-size: 0.8em;
                    padding: 0.5em 1.5em;
                }
            }
        }
    }

    .expanded_section {
        display: grid;
        grid-template-columns: repeat(2, 1fr); 
        gap: 1em;
        justify-items: start; 
        width: 100%;
        border-radius: 1em 1em 1em 1em;
        border: 1px solid #0000001A;
        padding: 1em 2em 1em 2em; 
        backdrop-filter: blur(16px) saturate(120%);
        background-color: #ECF7FF; 
        opacity: 0; 
        transition: transform 0.3s ease-out, opacity 0.3s ease-out;

        @media (max-width: 900px) {
            font-size: 1em;
        }

        .resources_page_link {
            color: #6c42e0;
        }
    }
    
    .expanded {
        opacity: 1;
    }
    
    .collapsed {
        opacity: 0; 
        pointer-events: none;
        touch-action: none;
        height: 0;
    }
       
}