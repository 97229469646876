.team {
    display: flex;
    flex-direction: column;
    color: #3A475A;
    text-transform: uppercase;
    font-family: "Space Grotesk";
    
    .committee {
        display: flex;
        flex-direction: column;
        align-items: center;

        @media screen and (max-width: 900px) {
            margin-bottom: 1em;            
        }

        .committee_text {
            font-size: 1.3em;
            margin-bottom: 0.2em;
            font-family: "Neue Haas Grotesk Text Pro";
        }

        .members {
            display: flex;
            column-gap: 4em;
            align-items: center;
            justify-content: center;

            @media screen and (max-width: 1200px) {
                column-gap: 2em;
            }
            @media screen and (max-width: 900px) {
                column-gap: 1em;
                row-gap: 0.5em;
                flex-wrap: wrap;
            }

            .member {
                display: flex;
                flex-direction: column;
                justify-content: start;
                align-items: center;
                width: 12em;
                height: 21em;

                @media screen and (max-width: 1200px) {
                    width: 11em;
                    height: 18em;
                }
                @media screen and (max-width: 900px) {
                    width: 10em;
                    height: 14em;
                }
                
                .image {
                    width: 14em;
                    height: 14em;
                    border-radius: 100%;
                    overflow: hidden;
                    background-color: #d9d9d9;
                    margin-bottom: 0.6em;

                    @media screen and (max-width: 1200px) {
                        width: 11em;
                        height: 11em;
                    }
                    @media screen and (max-width: 900px) {
                        width: 9em;
                        height: 9em;
                    }

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }

                .name {
                    font-weight: 600;
                    text-align: center;
                    @media (max-width: 900px) {
                        font-size: 0.8em;
                    }
                }
                
                .role {
                    font-weight: 400;
                    text-align: center;
                    line-height: 1.2em;
                    @media (max-width: 900px) {
                        font-size: 0.8em;
                    }
                }
            }
        }
    }
}