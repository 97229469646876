.countdown_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    // color: #3a475a;
    color: #ecf7ff;
    background-color: #bcbeff;

    &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(160deg, #373FF6 0%, #8961F4 35%, #FF2400 90%);
        z-index: 1;
        mix-blend-mode: lighten;
        pointer-events: none;
        touch-action: none;
    }
    
    z-index: -2;
    
    .timer {
        font-family: "OT Brut Mono";
        // font-family: "PP Supply Mono";
        display: flex;
        font-size: 8em;
        pointer-events: none;
        touch-action: none;

        @media (max-width: 900px) {
            // margin-top: 2em;
            font-size: 3em;
        }

        .binary {
            position: absolute;
            display: flex;
            width: 100%;
            left: 0;
            top: 0em;
            justify-content: center;
            align-items: center;
            gap: 0em;

            filter: blur(100px);
            
            @media (max-width: 900px) {
                filter: blur(50px);
            }

            .binary_digit {
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 0em;

                .bit {
                    width: 17vw;
                    height: 25vh;
                    transition: background-color 1s ease-out;
                }

                .on {
                    background-color: #8e32ff;
                    // background-color: #110033;
                }

                .off {
                    background-color: transparent;
                }
            }
        }

        .timer_item {
            z-index: 2;
            display: flex;
            margin: 0 0.5rem;
            
        }
    }
    
    .subtext {
        z-index: 1;
        font-family: "Neue Haas Grotesk Text Pro";
        font-size: 1em;
        font-weight: 600;
        margin-top: -2em;
        text-align: center;

        a {
            color: #ecf7ff;
            text-decoration: underline;
            transition: color 0.1s ease-out;

            &:hover {
                color: #ecf7ffD0;
            }
        }
        
        @media (max-width: 900px) {
            margin-top: -0.6em;
            font-size: 0.8em;
            width: 60%;
        }
    }

    .logo_link {
        position: absolute;
        bottom: 6em;

        .logo_white {
            width: 6em;
            z-index: 2;
            transition: opacity 0.1s ease-out;

            &:hover {
                opacity: 0.82;
            }
        }
    }
}