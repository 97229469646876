.resources_background {
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 800%;
    z-index: -1000;
    
    display: flex;
    justify-content: center;
    align-items: center;

    background-size: 200px;
    background-repeat: repeat-x;
    background-image: 
        linear-gradient(90deg, #0000000F 1px, transparent 1px),
        linear-gradient(0deg, #0000000F 1px, transparent 1px);

    @media (max-width: 900px) {
        background-image: none;
    }

    .circle {
        position: absolute;
        border-radius: 1000em;
        
        &__overlay {
            width: 80vh;
            height: 80vh;
            top: 10vh;
            background-color: #ffffff;
            mix-blend-mode: soft-light;
            opacity: 1;
            z-index: 1000;

            @media (max-width: 900px) {
                width: 110vh;
                height: 110vh;
                top: -45vh;
            }
        }
        
        &__gradient {
            width: 84vh;
            height: 84vh;
            top: 8vh;
            background: linear-gradient(120deg, #373FF6 0%, #8961F4 50%, #FF2400 100%);
            // opacity: 0.6;
            filter: blur(150px) saturate(150%);
            mix-blend-mode: soft-light;
            
            animation: rotate 15s linear infinite;
            @keyframes rotate {
                0% {
                    transform: rotate(0deg) ;
                }
                100% {
                    transform: rotate(360deg);
                }
            }

            @media (max-width: 900px) {
                width: 120vh;
                height: 120vh;
                top: -49vh;
                animation: rotate 15s linear infinite;
            }
        }
    }

    .blob {
        visibility: hidden;
        position: absolute;
        object-fit: none;
        overflow: visible;
        width: 170vh;
        height: 170vh;
        top: 180em;
        z-index: 1000;
        filter: saturate(1.1) brightness(1.1);

        @media (max-width: 900px) {
            width: 200vw;
            top: 70em;
        }
    }
}
