.gallery_container {
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
    border-radius: 1em;

    @media (max-width: 900px) {
        border-radius: 0;
    }
}

.gallery {
    display: flex;
    flex-direction: row;
}

.gallery img {
    width: 30em;
    height: auto;
    margin-right: 1em;
    border-radius: 1em;

    @media (max-width: 900px) {
        width: 40em;
    }
}
