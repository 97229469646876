.prizes_container {
    display: flex;
    flex-direction: column;
    align-items: center;

    .prizes_table {
        display: grid;
        grid-template-columns: repeat(4, minmax(0, 1fr));
        border-radius: 1em;
        width: 100%;
        gap: 1em;
    
        .cell {
            display: flex;
            flex-direction: column;
            padding: 2em 1.6em;
            border-radius: 1em;
            border: 1px solid #0000001A;
            transition: transform 0.1s;

            @media (max-width: 900px) {
                padding: 1.5em 1em;
            }

            &:hover {
                transform: translate(0.2em, -0.2em);
            }

            .category_name {
                font-family: "PP Editorial New";
                font-weight: 400;
                font-style: italic;
                margin-bottom: -0.1em;
                flex: 1;
            }
            
            .description {
                font-family: "Neue Haas Grotesk Text Pro";
                font-weight: 400;
                flex: 1;
            }

            .prize_description {
                font-family: "Neue Haas Grotesk Text Pro";
                font-weight: 800;
                bottom: 0;
                margin-top: 0.4em;
            }

            &__size_4 {
                grid-column: span 4 / span 4;
                background-color: #e5a3f5B0;
                background: linear-gradient(90deg, #f8d3f8 0%, #eeddf8 100%);

                .category_name {
                    font-size: 3em;

                    @media (max-width: 900px) {
                        font-size: 2em;
                    }
                }

                .description {
                    font-size: 1.2em;

                    @media (max-width: 900px) {
                        font-size: 0.9em;
                    }
                }

                .prize_description {
                    font-size: 1.2em;

                    @media (max-width: 900px) {
                        font-size: 0.9em;
                    }
                }
            }
    
            &__size_2 {
                grid-column: span 2 / span 2;
                background-color: #eeddf8;
                background: linear-gradient(90deg, #f8d3f870 0%, #eeddf870 100%);

                @media (max-width: 900px) {
                    grid-column: span 4 / span 4;
                }

                .category_name {
                    font-size: 2em;

                    @media (max-width: 900px) {
                        font-size: 1.5em;
                    }
                }

                .description {
                    font-size: 1em;

                    @media (max-width: 900px) {
                        font-size: 0.9em;
                    }
                }

                .prize_description {
                    font-size: 1em;

                    @media (max-width: 900px) {
                        font-size: 0.9em;
                    }
                }
            }

            &__size_2_small {
                grid-column: span 2 / span 2;
                background-color: transparent;
                // background: linear-gradient(90deg, #f8d3f870 0%, #eeddf870 100%);

                @media (max-width: 900px) {
                    grid-column: span 4 / span 4;
                }

                .category_name {
                    font-size: 2em;

                    @media (max-width: 900px) {
                        font-size: 1.5em;
                    }
                }

                .description {
                    font-size: 1em;

                    @media (max-width: 900px) {
                        font-size: 0.9em;
                    }
                }

                .prize_description {
                    font-size: 1em;

                    @media (max-width: 900px) {
                        font-size: 0.9em;
                    }
                }
            }

            &__size_4_small {
                grid-column: span 4 / span 4;
                background-color: transparent;

                .category_name {
                    font-size: 2em;

                    @media (max-width: 900px) {
                        font-size: 1.5em;
                    }
                }

                .description {
                    font-size: 1em;

                    @media (max-width: 900px) {
                        font-size: 0.9em;
                    }
                }

                .prize_description {
                    font-size: 1em;

                    @media (max-width: 900px) {
                        font-size: 0.9em;
                    }
                }
            }
        }

        .subtable_container {
            background-color: transparent;
            padding: 0;
        }
    }

    .disclaimer {
        margin-top: 1em;
        font-size: 0.9em;
        text-align: center;
        color: #000000BB;
    }
}

// vvvvvv may not be needed vvvvvv
.subtable {
    display: grid;
    // grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 1em;

    .cell {
        .category_name {
            font-size: 0.1em;
        }

        .description {
            font-size: 1.2em;
        }
    }
}