.questions {
    display: flex;
    flex-direction: column;
    margin-top: 1em;
    padding: 0 8em;
    @media (max-width: 900px) {
        padding: 0 1em;
    }

    .question {
        border-bottom: 1px solid #000000;
        
        &:first-child {
            border-top: 1px solid #000000;
        }
        
        .header {
            display: flex;
            justify-content: space-between;
            font-family: "Neue Haas Grotesk Text Pro";
            color: #000000;
            font-style: normal;
            font-size: 2em;
            cursor: pointer;
            
            @media (max-width: 900px) {
                font-size: 1.2em;
            }
            
            .question__title {
                padding: 0.25em 0;
                font-weight: 400;
                text-align: left;
            }
            
            .arrow {
                width: 0.8em;
                transform: rotate(0deg);
                transition: all 0.3s ease-out;
            }
            
            .arrow__down {
                transform: rotate(90deg);
                transition: all 0.3s ease-out;
            }
        }
        
        .question__answer {
            color: #000000;
            overflow: hidden;
            font-size: 1.2em;
            max-height: 0;
            transition: all 0.3s ease-out;

            a {
                color: #007BFF; 
                text-decoration: underline; 
                transition: color 0.3s ease;
            }

            a:hover {
                color: #0056b3; 
            }

            @media (max-width: 900px) {
                font-size: 1em;
            }
        }
        
        .question__answer_show {
            color: #000000;
            overflow: hidden;
            font-size: 1.2em;
            max-height: 16em;
            padding-bottom: 1em;
            transition: all 0.3s ease-out;

            a {
                color: #007BFF; 
                text-decoration: underline; 
                transition: color 0.3s ease;
            }

            a:hover {
                color: #0056b3; 
            }

            @media (max-width: 900px) {
                font-size: 1em;
                max-height: 26em;
            }
        }
    }
}