.socials {
    display: flex;
    justify-content: center;
    align-items: center;

    .icon {
        color: #3a475a;
        margin: 0 0.4em;
        font-size: 1.5em;
        cursor: pointer;
        z-index: 1;

        transition: color 0.1s ease-out;

        &:hover {
            color: #3a475aD0;
        }

        @media (max-width: 900px) {
            font-size: 1.5em;
        }
    }
}