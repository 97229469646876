@font-face {
    font-family: "PP Editorial New";
    src: url(../asset/font/PPEditorialNew-Italic.otf);
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: "PP Editorial New";
    src: url(../asset/font/PPEditorialNew-Regular.otf);
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "PP Editorial New";
    src: url(../asset/font/PPEditorialNew-Ultrabold.otf);
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: "PP Editorial New";
    src: url(../asset/font/PPEditorialNew-UltraboldItalic.otf);
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: "PP Editorial New";
    src: url(../asset/font/PPEditorialNew-Ultralight.otf);
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: "PP Editorial New";
    src: url(../asset/font/PPEditorialNew-UltralightItalic.otf);
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: "PP Supply Mono";
    src: url(../asset/font/PPSupplyMono-Regular.otf);
    font-weight: 400;
}

@font-face {
    font-family: "PP Supply Mono";
    src: url(../asset/font/PPSupplyMono-Ultralight.otf);
    font-weight: 300;
}

@font-face {
    font-family: "PP Supply Sans";
    src: url(../asset/font/PPSupplySans-Regular.otf);
    font-weight: 400;
}

@font-face {
    font-family: "PP Supply Sans";
    src: url(../asset/font/PPSupplySans-Ultralight.otf);
    font-weight: 300;
}

@font-face {
    font-family: "Space Grotesk";
    src: url(../asset/font/SpaceGrotesk-VariableFont_wght.ttf);
}

@font-face {
    font-family: "Neue Haas Grotesk Text Pro";
    src: url(../asset/font/NHaasGroteskTXPro-Rg.ttf);
    font-weight: 400;
}

@font-face {
    font-family: "Neue Haas Grotesk Text Pro";
    src: url(../asset/font/NHaasGroteskTXPro-It.ttf);
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: "OT Brut";
    src: url(../asset/font/OTBrut-Regular.otf);
    font-weight: 400;
}

@font-face {
    font-family: "OT Brut";
    src: url(../asset/font/OTBrut-Italic.otf);
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: "OT Brut Mono";
    src: url(../asset/font/OTBrut-RegularMono.otf);
    font-weight: 400;
}

@font-face {
    font-family: "OT Brut Mono";
    src: url(../asset/font/OTBrut-ItalicMono.otf);
    font-weight: 400;
    font-style: italic;
}