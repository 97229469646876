.footer_container {
    background-color: #ECF7FF;
    display: flex;
    flex-direction: column;
    border-radius: 1em 1em 0 0;
    padding: 2.5em;
    padding-bottom: 1.8em;
    align-items: center;
    row-gap: 0.5em;
    width: 100%;

    @media (max-width: 900px) {
        font-size: 0.8em;
    }

    .hackviolet {
        display: flex;
        justify-content: center;
        align-items: center;
        column-gap: 1em;

        .logo {
            width: auto;
            height: 2em;
        }

        @media (max-width: 900px) {
            flex-direction: column;
            row-gap: 0.8em;
        }

        .contact {
            line-height: 1;

            
            .label {
                @media (max-width: 900px) {
                    visibility: hidden;
                    height: 0;
                }
                font-size: 0.8em;
            }
            .email {
                font-size: 1.5em;

                @media (max-width: 900px) {
                    font-size: 1.2em;
                }
            }
        }
    }

    .copyright {
        p a {
            text-decoration: underline;
        }
    }
}


// https://static.mlh.io/docs/mlh-code-of-conduct.pdf