.schedule {
    font-size: 0.9em;
    display: flex;
    flex-direction: column;
    margin-top: 1em;
    padding: 0 8em;
    @media (max-width: 900px) {
        padding: 0 1em;
    }

    .buttons_container {
        display: flex;
        justify-content: space-between;

        @media (max-width: 900px) {
            flex-direction: column;
            align-items: center;
            gap: 0.5em; 
        }
        
        .tabs {
            display: flex;
            justify-content: center;
            font-family: "Space Grotesk";
            font-weight: 800;
            font-size: 1.5em;
            
            
            @media (max-width: 900px) {
                font-size: 1em;
            }
            
            
            .tab_button {
                &:first-child {
                    border-radius: 0.5em 0 0 0;
                }
                &:last-child {
                    border-radius: 0 0.5em 0 0;
                }
                
                // padding: 0.9em 1.5em;
                padding: 0.4em 1.4em;
                border: 1px solid #0000001A;
                // margin: 0 0.5em;
                color: black;
                background-color: transparent;
                cursor: pointer;
                transition: background-color 0.3s ease;
                
                &.active {
                    background-color: #3A475A;
                    color: white;
                }
                
                @media (max-width: 900px) {
                    font-size: 1em;
                    padding: 0.6em 1em;
                }
            }
        }
    
        .event_filters {
            display: flex;
            justify-content: center;
            // margin: 1em 0em;
            font-family: "Space Grotesk";
            font-weight: 800;
            font-size: 1.2em;
            flex-wrap: wrap;  
            
            @media (max-width: 900px) {
                order: -1;
                font-size: 1em;
                justify-content: center; 
            }
        
            .filter_button {
                padding: 0.2em 1.2em;
                border: 1px solid #0000001A;
                border-radius: 1.2em;
                margin: 0.4em 0.2em;
                background-color: transparent;
                color: black;
                cursor: pointer;
                transition: background-color 0.3s ease;
                
                &.active {
                    background-color: #5d7291;
                    color: white;
                }
    
                &.HackViolet.active {
                    background-color: #6a4c9c;
                    color: white;
                }
                &.Club.active {
                    background-color: #ff6347;
                    color: white;
                }
                &.Meal.active {
                    background-color: #28a745;
                    color: white;
                }
                &.MLH.active {
                    background-color: #00bcd4;
                    color: white;
                }
            }
        }
        
    }

    .event_list {
        display: flex;
        justify-content: center;
        border: 1px solid #0000001A;
        border-radius: 0 0.5em 0.5em 0.5em;
        background-color: #ffffff;
        font-size: 1.2em;

        @media (max-width: 900px) {
            font-size: 1em;
        }

        .scrollable_content {
            max-height: 80vh;
            overflow-y: auto;

            @media (max-width: 900px) {
                max-height: 60vh;
            }
        }

        .event_item {
            &:first-child {
                border-radius: 0 0.5em 0 0;
            }
            &:last-child {
                border-radius: 0 0 0.5em 0.5em;
            }

            display: flex;
            flex-direction: row;
            align-items: flex-start;
            padding: 1em 1.5em;
            border: 1px solid #0000001A;
            transition: background-color 0.3s ease;
            width: 100vw;
            max-width: 55vw;

            @media (max-width: 1360px) {
                width: 100vw;
                max-width: 80vw;
            }
            @media (max-width: 900px) {
                width: 100vw;
                max-width: 80vw;
                padding: 1em;
            }

            &:hover {
                background-color: #f1f1f1;
            }

            .event_time {
                flex: 2;
                font-weight: bold;
                font-size: 1em;

                @media (max-width: 900px) {
                    font-size: 0.8em;
                }
            }

            .event_details {
                flex: 3;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                margin-left: 1em;
                font-size: 1em;

                @media (max-width: 900px) {
                    font-size: 0.8em;
                }

                .event_location {
                    display: flex;
                    align-items: center;
                    gap: 0.3em;
                }

                .event_type_marker {
                    margin-top: 0.5em;
                    padding: 0.5em 1em;
                    border: 2px solid transparent;
                    border-radius: 50%;
                    display: inline-block;
                    font-size: 0.9em;
                    font-weight: bold;
                    text-align: center;
                    line-height: 1;
                    background-color: transparent;
                    border-color: #ddd;
                }
            }
        }
    }
}