.sponsors_container {
    color: #3A475A;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 4em;
    @media (max-width: 900px) {
        padding: 0 1em;
    }

    .tier_container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 4em; // space between tiers
        margin-bottom: 4em;

        .tier {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            gap: 1em; // space between tier name and logos

            p {
                font-family: "PP Supply Sans";
                text-transform: uppercase;
                font-size: 1.3em;
            }

            .logo_container {
                display: flex;
                justify-content: center;
                align-items: center;
                row-gap: 1.4em; // space between logos
                column-gap: 3em; // space between logos
                flex-wrap: wrap;
            }
        }

        .orchid {
            p {
                color: #de6da3;
            }

            .logo_container .company_logo {
                max-height: 10em;
                
                @media (max-width: 900px) {
                    max-height: 10em;
                }
            }
        }
        
        
        .lilac {
            p {
                color: #ab64be;
            }
            
            .logo_container .company_logo {
                max-height: 5em;
                
                @media (max-width: 900px) {
                    max-height: 2em;
                }
            }    
        }
        
        .lavender {
            p {
                color: #725bf2;
            }
            
            .logo_container .company_logo {
                max-height: 2em;

                @media (max-width: 900px) {
                    max-height: 1em;
                }
            }    
        }

        .company_logo {
            max-height: 4em;
            height: auto;
            transition: transform 0.1s;
            display: inline;

            &:hover {
                transform: translate(0.14em, -0.14em);
            }
        }
    }


    .copy {
        margin-bottom: 1em;
        font-family: "Neue Haas Grotesk Text Pro";
        font-size: 1.4em;
        font-weight: 400;
        line-height: 1.4em;
    
        p {
            margin-bottom: 1.2em;
        }
    
        @media (max-width: 900px) {
            font-size: 1em;
        }
    }
}
