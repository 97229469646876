.background {
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 220%;
    z-index: -1000;
    // background-color: #B4DAE7;

    background: linear-gradient(180deg, #01A6B859 -50%, #B4DAE759 100%);
    
    display: flex;
    justify-content: center;
    align-items: center;
    
    // .gradient {
    //     position: absolute;
    //     top: 0;
    //     background: linear-gradient(120deg, #373FF6 0%, #8961F4 63%, #FF2400 100%);
    //     width: 100vw;
    //     height: 100vh;
    // }

    .circle {
        position: absolute;
        border-radius: 1000em;
        
        &__overlay {
            // width: 105em;
            // height: 105em;
            width: 162vh;
            height: 162vh;
            top: -75vh;
            background-color: #ffffff;
            mix-blend-mode: soft-light;
            opacity: 1;
            z-index: 1000;

            @media (max-width: 900px) {
                width: 110vh;
                height: 110vh;
                top: -45vh;
            }
        }
        
        &__gradient {
            width: 170vh;
            height: 170vh;
            top: -78.5vh;
            background: linear-gradient(120deg, #373FF6 0%, #8961F4 63%, #FF2400 100%);
            // opacity: 0.6;
            filter: blur(250px) saturate(150%);
            mix-blend-mode: soft-light;
            
            animation: rotate 15s linear infinite;
            @keyframes rotate {
                0% {
                    transform: rotate(0deg) ;
                }
                100% {
                    transform: rotate(360deg);
                }
            }

            @media (max-width: 900px) {
                width: 120vh;
                height: 120vh;
                top: -49vh;
                animation: rotate 15s linear infinite;
            }
        }
    }

    .blob {
        visibility: hidden;
        position: absolute;
        object-fit: none;
        overflow: visible;
        width: 170vh;
        height: 170vh;
        top: 180em;
        z-index: 1000;
        filter: saturate(1.1) brightness(1.1);

        @media (max-width: 900px) {
            width: 200vw;
            top: 70em;
        }
    }
}
